export function setStorage (key, val) {
  if (!key) return
  localStorage.setItem(key, JSON.stringify(val))
  localStorage.setItem('time', JSON.stringify(new Date()))
}

export function getStorageByKey (key) {
  if (!key) return null
  if (
    new Date().getTime() -
      new Date(JSON.parse(localStorage.getItem('time'))).getTime() <
    1000 * 60 * 60 * 24 * 30
  ) {
    return JSON.parse(localStorage.getItem(key))
  }
}
