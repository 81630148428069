import { requestInsTwo } from '@/utils/axios'
import { stringify } from 'qs'

// 账号密码登录
export function loginApi (params) {
  return requestInsTwo({
    url: `/user/login/password?${stringify(params)}`,
    method: 'post'
  })
}

// 查询用户积分、金币、贡献
export function queryUserJf () {
  return requestInsTwo({
    url: '/user/point/query',
    method: 'get'
  })
}

// 将用户积分兑换为doufu积分
export function jfToDoufu (params) {
  return requestInsTwo({
    url: `/user/point/convert/douFu?${stringify(params)}`,
    method: 'post'
  })
}
