<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view></router-view>
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'

export default {
  data () {
    return {
      locale: zhCN
    }
  }
}
</script>
<style>
html,body,#app {
  height: 100%;
}
body {
  margin: 0;
  color: #333;
}
</style>
