<template>
  <a-layout>
    <a-layout-header>
      <div class="header-title">
        <span class="menu-icon" v-if="!isLarge" @click="toggleMenu">
          <UnorderedListOutlined />
        </span>
        午安后台管理系统
      </div>
    </a-layout-header>
    <a-layout>
      <a-layout-sider v-show="showMenu">
        <a-menu
          v-model:selectedKeys="selectedKeys"
          mode="inline"
          @click="handleClickMenu"
        >
          <!-- <a-menu-item key="star">星球管理</a-menu-item> -->
          <a-menu-item key="menu">菜单管理</a-menu-item>
          <a-menu-item key="topic">话题管理</a-menu-item>
          <a-menu-item key="category">一级类目</a-menu-item>
          <a-menu-item key="category2">二级类目</a-menu-item>
          <a-menu-item key="category3">三级类目</a-menu-item>
          <a-menu-item key="brand">品牌管理</a-menu-item>
          <a-menu-item key="item">单品管理</a-menu-item>
          <a-menu-item key="tag">入口管理</a-menu-item>
          <a-menu-item key="version">版本更新</a-menu-item>
          <a-menu-item key="active">互助管理</a-menu-item>
          <a-menu-item key="ad">广告管理</a-menu-item>
          <a-menu-item key="mptag">小程序入口</a-menu-item>
          <a-menu-item key="report">举报管理</a-menu-item>
          <a-menu-item key="vswitch">版本开关</a-menu-item>
          <a-menu-item key="timingsend">定时群发</a-menu-item>
          <a-menu-item key="wechart">微信群绑定</a-menu-item>
          <a-menu-item key="redpacket">红包管理</a-menu-item>
          <a-menu-item key="mpset">小程序配置</a-menu-item>
          <a-menu-item key="cover">封面管理</a-menu-item>
          <a-menu-item key="entry">词条管理</a-menu-item>
        </a-menu>
      </a-layout-sider>
      <a-layout-content>
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { onMounted, ref } from "vue";
import useDefaultLayout from "@/js/useDefaultLayout";
import { UnorderedListOutlined } from "@ant-design/icons-vue";
export default {
  components: {
    UnorderedListOutlined,
  },
  setup() {
    const { selectedKeys, handleClick } = useDefaultLayout();

    const showMenu = ref(false);
    const isLarge = ref(true);
    const toggleMenu = () => (showMenu.value = !showMenu.value);
    const hideMenu = () => (showMenu.value = false);
    const handleClickMenu = (obj) => {
      handleClick(obj);
      if (!isLarge.value) {
        hideMenu();
      }
    };

    onMounted(() => {
      if (document.body.clientWidth > 750) {
        isLarge.value = true;
        showMenu.value = true;
      } else {
        isLarge.value = false;
        showMenu.value = false;
      }
    });

    return {
      selectedKeys,
      handleClickMenu,
      showMenu,
      toggleMenu,
      hideMenu,
      isLarge,
    };
  },
};
</script>
<style lang="scss" scoped>
.ant-layout {
  height: 100%;
  background: #fff;
}

.ant-layout-sider {
  background-color: #fff;
}

.ant-layout-header {
  background-color: #fff;
  box-shadow: 0 2px 2px #bfbfbf;
  z-index: 1;
}

.ant-layout-content {
  padding: 10px;
}

.header-title {
  display: flex;
  align-items: center;
}

.menu-icon {
  display: inline-block;
  font-size: 20px;
  margin-right: 10px;
}

@media only screen and (max-width: 750px) {
  .ant-layout-header {
    padding: 0 10px;
  }
}
</style>
