import { getStorageByKey } from './localStorage'
import { userInfo } from '@/js/useLogin'

export function setUserInfo () {
  const userInfoObj = getStorageByKey('userInfo')
  userInfo.value = {
    ...userInfo.value,
    ...userInfoObj
  }
}
