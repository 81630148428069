import { createRouter, createWebHashHistory } from "vue-router";
import DefaultLayout from "@/layout/DefaultLayout";
// import { userInfo } from '@/js/useLogin'

// const whiteList = ['/login']
const routes = [
  {
    path: "/",
    name: "index",
    redirect: "/menu",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    component: DefaultLayout,
    children: [
      // {
      //   // 星球管理
      //   path: "star",
      //   name: "star",
      //   component: () => import("../views/PlanetManage.vue")
      // },
      {
        // 菜单管理
        path: "menu",
        name: "menu",
        component: () => import("../views/MenuManage.vue"),
      },
      {
        // 话题管理
        path: "topic",
        name: "topic",
        component: () => import("../views/TopicManage.vue"),
      },
      {
        // 一级类目
        path: "category",
        name: "category",
        component: () => import("../views/CategoryManage.vue"),
      },
      {
        // 二级类目
        path: "category2",
        name: "category2",
        component: () => import("../views/Category2Manage.vue"),
      },
      {
        // 三级类目
        path: "category3",
        name: "category3",
        component: () => import("../views/Category3Manage.vue"),
      },
      {
        // 单品管理
        path: "item",
        name: "item",
        component: () => import("../views/ItemManage.vue"),
      },
      {
        // 版本更新
        path: "version",
        name: "version",
        component: () => import("../views/VersionManage.vue"),
      },
      {
        path: "brand",
        name: "brand",
        component: () => import("../views/BrandManage.vue"),
      },
      {
        path: "tag",
        name: "tag",
        component: () => import("../views/Tag.vue"),
      },
      {
        path: "active",
        name: "active",
        component: () => import("../views/ActiveManage.vue"),
      },
      {
        path: "ad",
        name: "ad",
        component: () => import("../views/AdManage.vue"),
      },
      {
        path: "mptag",
        name: "mptag",
        component: () => import("../views/MpTag.vue"),
      },
      {
        path: "report",
        name: "report",
        component: () => import("../views/ReportManage.vue"),
      },
      {
        path: "vswitch",
        name: "vswitch",
        component: () => import("../views/VersionSwitch.vue"),
      },
      {
        path: "timingsend",
        name: "timingsend",
        component: () => import("../views/TimingSend.vue"),
      },
      {
        path: "wechart",
        name: "wechart",
        component: () => import("../views/WeChart.vue"),
      },
      {
        path: "redpacket",
        name: "redpacket",
        component: () => import("../views/RedPacketManage.vue"),
      },
      {
        path: "mpset",
        name: "mpset",
        component: () => import("../views/MpSet.vue"),
      },
      {
        path: "cover",
        name: "cover",
        component: () => import("../views/CoverUpload.vue"),
      },
      {
        path: "entry",
        name: "entry",
        component: () => import("../views/EntryManage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
// router.beforeEach((to, from, next) => {
//   const { path } = to
//   if (whiteList.some(key => path === key)) {
//     next()
//     return
//   }
//   if (userInfo.value && userInfo.value.token) {
//     next()
//   } else {
//     next({ name: 'login' })
//   }
// })

export default router;
