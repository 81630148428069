import axios from "axios";
import urls from "@/common/urls";
import { userInfo } from "@/js/useLogin";

const createRequest = (baseUrl, headers = {}) => {
  const request = axios.create({
    baseURL: baseUrl,
    timeout: 15000,
    headers,
  });
  request.interceptors.request.use(
    (config) => {
      config = {
        ...config,
        headers: {
          ...config.headers,
          token: userInfo ? userInfo.value.token : "",
        },
      };
      return config;
    },
    (error) => Promise.reject(error)
  );
  request.interceptors.response.use(
    (res) => {
      return res.data;
    },
    (error) => {
      console.log(error);
    }
  );
  return request;
};

export const requestIns = createRequest(`${urls.apiUrl}/wuan`);
export const requestInsTwo = createRequest(urls.apiUrl);
export const request = createRequest(urls.apiUrl, {
  "content-type": "application/x-www-form-urlencoded",
});
export const request2 = createRequest(urls.apiUrl2, {
  // "content-type": "application/x-www-form-urlencoded",
});
export const requestJSON = createRequest(urls.apiUrl, {
  "content-type": "application/json;charset=utf-8",
});
export const requestForm = createRequest(urls.apiUrl, {
  "content-type": "multipart/form-data;",
});
export default { requestIns, requestInsTwo, request, requestJSON, request2 };
