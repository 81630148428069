import { loginApi } from '@/api/login'
import { ref } from 'vue'
import { message } from 'ant-design-vue'
import router from '@/router'
import { setStorage } from '@/utils/localStorage'

export const userInfo = ref({})

export default function useLogin () {
  const formRef = ref()
  const loading = ref(false)
  const loginForm = ref({
    phoneNumber: '',
    password: ''
  })
  const loginRules = {
    phoneNumber: [{ required: true, message: '必填项', trigger: 'blur' }],
    password: [{ required: true, message: '必填项', trigger: 'blur' }]
  }
  const toLogin = () => {
    formRef.value.validate().then(() => {
      loading.value = true
      loginApi(loginForm.value).then(({ msg, data, status }) => {
        loading.value = false
        if (status !== '200') {
          message.warning(msg)
        } else {
          userInfo.value = data
          setStorage('userInfo', data)
          router.push('/')
        }
      })
    })
  }

  return {
    formRef,
    loading,
    loginForm,
    loginRules,
    toLogin
  }
}
