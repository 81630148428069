import { ref } from 'vue'
import router from '@/router'

export default function useDefaultLayout () {
  const hash = location.hash.replace("#/","")
  const selectedKeys = ref([hash == undefined ? 'star' : hash])
  const handleClick = ({ item, key, keyPath }) => {
    // console.log(item, key, keyPath)
    router.push(key)
  }

  return {
    selectedKeys,
    handleClick
  }
}
